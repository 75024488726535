.Main {
  background-color: black;
  width: 100%;
  background-image: url("./../Assets/foot.png");
}

.Top {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
}
.header {
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  align-items: center;
  margin-bottom: 24px;
}
.header h1 {
  font-weight: 900;
  font-size: 40px;
  font-family: "Poppins", sans-serif;
  color: white;
}
.p p {
  font-family: "Poppins", sans-serif;
  width: 55.244299674267104vw;
  font-size: 16px;
  text-align: center;

  font-weight: 400;
  margin-bottom: 51px;
  color: #656565;
}
.button {
  margin-bottom: 60px;
  border-radius: 104px;
  font-size: 18px;
  font-weight: 500;
  height: 52px;
  width: 165px;
  background-color: #3845b8;
  border: 1px solid #3845b8;
  color: white;
}
.bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bottom1 {
  display: flex;
  flex-wrap: wrap;
}
.one {
  height: 199px;
  display: flex;
  flex-direction: column;
  width: 16.93159609120521vw;
  margin-left: 5.01628664495114vw;
}
.one img {
  height: 70px;
  width: 162px;
  margin-bottom: 40px;
}
.one p {
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
}
.two {
  display: flex;
  margin-left: 9.250814332247558vw;
  margin-right: 8.469055374592834vw;
}
.subOne {
  display: flex;
  flex-direction: column;
  width: 14.50814332247557vw;
  row-gap: 12px;
}
h3 {
  margin-bottom: 20px;
}
.subTwo {
  display: flex;
  flex-direction: column;
  width: 17.182410423452769vw;
  row-gap: 12px;
}
a {
  color: #ffffff;
  text-decoration: none;
}


.subOne h3 {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
}
.subThreeOne h3 {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
}
.subTwo h3 {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
}
.subOne p {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: white;
}
.subThreeOne p {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: white;
}
.subTwo p {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: white;
}

.subThreeOne {
  display: none;
}
.subThreeTwo {
  display: flex;
  flex-direction: column;
  row-gap: 14px;
}
.subThreeTwo h3 {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: #ffffff;
  font-weight: 600;
}

.subThreeTwo p {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 300;
  max-width: 307px;
  color: #ffffff;
}
.subThreeTwo span {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
}
.subThreeTwo img {
  width: 30px; 
  height: 40px;
  object-fit: contain;
  margin-right: 25px;
}
 
.bottom2 {
  margin: 45px 0 15px 0;
}
.bottom2 p {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}
.bottom2 span {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
}
@media only screen and (max-width: 1092px) {
  .subTwo {
    min-width: 160px;
  }
  .two {
    justify-content: space-between;
    width: 50%;
  }
  .subOne {
    width: 110px;
  }
  .three {
    margin-left: 5.0552083333333335vw;
    margin-top: 10px;
  }
}
@media only screen and (max-width: 647px) {
  .subOne,.subTwo {
    width: 100%;
  }
  .one {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
  .header h1 {
    font-size: 20px;
  }
  .p p {
    font-size: 10px;
  }
  .one p {
    width: 70%;
  }

  .two {
    justify-content: space-between;
    width: 90%;
  }
  .three {
    margin-left: 9.0552083333333335vw;
  }
}

@media only screen and (max-width: 408px) {
  .one {
    margin-bottom: 20px;
  }

  .two {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
}
