* {
  font-family: "Poppins", sans-serif;
}
.App {
  /* width: 100%; */
  height: 100vh;
  background-color: #eceae7;
}
html,
body,
li,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}
.main_container {
  padding: 0 65px;
}
.text_center {
  text-align: center !important;
}

@media (max-width: 769px) {
  .main_container {
    padding: 0 35px;
  }
  .text_center {
    text-align: start !important;
  }
}

.section_gap {
  padding: 80px 0 !important;
}
.main_title {
  font-size: 42px;
  font-weight: 500;
}
.main_title span {
  color: #3845b8;
  font-weight: 600;
}
.mb-1 {
  margin-bottom: 10px;
}
.mb-2 {
  margin-bottom: 20px;
}
.mb-3 {
  margin-bottom: 30px;
}
.mb-4 {
  margin-bottom: 40px;
}
.mb-5 {
  margin-bottom: 50px;
}
.mt-1 {
  margin-top: 10px;
}
.mt-2 {
  margin-top: 20px;
}
.mt-3 {
  margin-top: 30px;
}
.mt-4 {
  margin-top: 40px;
}
.mt-5 {
  margin-top: 50px;
}
.pb_0 {
  padding-bottom: 0 !important;
}
.mb-100 {
  margin-bottom: 100px;
}
.blue_color {
  color: #3845b8;
}
.custom_list li:before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  left: 0px;
  top: 10px;
  background: #3845b8;
}
.custom_list li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 20px;
}
.custom_list {
  list-style: none;
}
.btn_main {
  background-color: #3845b8;
  color: #fff;
  display: block;
  padding: 12px 30px;
  border: 0;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  width: 100%;
}

.contact_form input {
  display: block;
  width: 100%;
  height: 50px;
  margin-bottom: 15px;
  padding: 10px;
}
.contact_form textarea {
  width: 100%;

  margin-bottom: 15px;
  padding: 10px;
}

.two_col_wrapper .two_col_wrapper_single {
  width: 50%;
}
.two_col_wrapper {
  display: flex;
  gap: 40px;
}
.contact_info h4 {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}
.contact_info p {
  margin-bottom: 15px;
}
.contact_info p a {
  color: #3845b8;
  padding-left: 10px;
  text-decoration: none;
  font-weight: 700;
}

@media (max-width: 1199px) {
  .aboutMain {
    margin-top: 400px;
  }
}
@media (max-width: 640px) {
  .main_title {
    font-size: 28px !important;
    font-weight: 600;
  }
  .main_title span {
    font-size: 28px !important;
    font-weight: 600;
  }
  #footer h1{
    text-align: left;
    width: 100%;
  }
  #footer p{
    text-align: left;
    width: 75%;
    margin: 0 auto;
    margin-bottom: 25px;
  }
   
  #company-info{
    align-items: flex-start;
  }
  #company-info p{
    width: 100%;
  }
  #footer_menu_list p, #footer_contact_list p{
    width: 100%;
  }
  .social_media {
    display: flex;
  }
}
