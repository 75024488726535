.main
{
    height: 100vh;
    display: flex;
}
.left
{
    width: 52%;
    margin-top: 38px;
    padding-left: 10.52vw;
}
.new
{
    display: none;
}
.right
{
    margin-top: 30px;
    width: 48%;
    display: flex;
    align-items: center;
}
.header
{
    width: 35.04vw;
    min-width: 234px;
}
.header h1
{
    font-size: 2.99vw;
    font-family: 'Poppins', sans-serif;
    font-weight: 800;
    color: #636466;
}
.content
{
    width: 35.0vw;
}
.content p
{
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    margin-top: 8px;
    text-align: justify;
    text-justify: inter-word;
}

@media(max-width: 999px){
    .content p
    {
        margin-left: -40px;
    }
}

.getQuote
{
    margin-top: 23px;
    width: 9.77vw;
    background-color: black;
    font-weight: 400;
    color: white;
    height: 6.313vh;
    font-size: 18px;
    min-height: 31px;
    min-width: 93px;
}
.trip
{
    margin-top: 29px;
}
.input
{
    width:410px;
    height: 48.2vh;
    position: absolute;
    top: 520px;
    left: 50.071vw;
    min-width: 343px;
    background-color: white;
}

.heek
{
    position: relative;
    
}
.rightarrow
{
    background-color: #636466;
    z-index: 10000000000000000;
    height: 100px;
    width: 100px;
}
.input
{
    display: flex;
    flex-direction: column;
}
input[type='date']:in-range::-webkit-datetime-edit-year-field,input[type='date']:in-range::-webkit-datetime-edit-month-field,input[type='date']:in-range::-webkit-datetime-edit-day-field,input[type='date']:in-range::-webkit-datetime-edit-text{  color: transparent;}
.Mainss
{
    display: flex;
    justify-content: space-between;
    padding: 0 10px 0 20px;
}
input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: url("./../Assets/calendar.png") no-repeat scroll 7px 0px;
    border-width: thin;
    padding-left:1200px;
}
input[type="time"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: url("./../Assets/clock.png") no-repeat scroll 0px 0px;
    border-width: thin;
    padding-left:1200px;
}
.children
{
    background: url("./../Assets/person.png") no-repeat scroll 7px 7px;
padding-left:30px;
border: 1px solid #DADADA;
height: 40px;
width: 35%;
margin-left: 35px;
}

@media(max-width: 650px){
    .children{
        width: 33%;
        margin-left: 16px;
    }
}

@media(max-width: 480px){
    .children{
        width: 36%;
        margin-left: 25px;
    }
}

@media(max-width: 380px){
    .children{
        margin-left: 20px;
    }
}

.bag
{
    border: 1px solid #DADADA;
    background: url("./../Assets/bag.png") no-repeat scroll 7px 7px;
padding-left:30px;
height: 40px;
width: 35%;
margin-left: 10px;
}

@media(max-width: 650px){
    .bag{
        width: 33%;
        margin-left: 0px;
    }
}

@media(max-width: 480px){
    .bag{
        width: 36%;
        margin-left: 20px;
    }
}

@media(max-width: 380px){
    .bag{
        width: 36%;
        margin-left: 0px;
    }
}

.ll
{
    display: flex;
    flex-direction: column;
    gap: 19px;
    margin-top: 11.2px;
}
.datetime
{
    width: 90%;
    display: flex;
    margin-left: 60px;
}
@media(max-width: 640px){
    .datetime
{
    margin-left: 45px;
}
}

@media(max-width: 380px){
    .datetime
{
    width: 80%;
    gap: 10px;
    justify-content: center;
}
}
.datetime input
{
    height: 44px;
    width:140px;
    border: 1px solid #DADADA;
    padding-left: 10px;
}

@media(max-width: 640px){
    .datetime input
{
    height: 44px;
    width:110px;
    border: 1px solid #DADADA;
}
}

@media(max-width: 380px){
    .datetime input
{
    height: 44px;
    width: 90px;
    border: 1px solid #DADADA;
}
}

.datetime2
{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    gap: 10px;
}

@media(max-width: 380px){
    .datetime2
{
    width: 100%;
    height: 40px;
    justify-content: space-evenly;
    margin-left: 10px;
}
}

@media(max-width: 480px){
    .datetime2
{
    width: 90%;
    height: 40px;
    justify-content: space-evenly;
    margin-left: 10px;
}
}

.datetime2 input
{
    height: 64px;
    width:152px;
    border: 1px solid #DADADA;
}

@media(max-width: 480px){
    .datetime2 input
{
    width: 80%;
    height: 40px;
    justify-content: space-evenly;
    margin-left: 10px;
}
}

.inputs
{
    width: 300px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.headd
{
    margin-bottom: 31px;
    display: flex;
    justify-content: center;
    margin-top: 12px;
}
.headd h1{
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}
.inputs input
{
    height: 40px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
}
.input1
{
    outline: 0;
    border-width: 0 0 2px;
    border-color: #DADADA;
    padding-bottom: 20px;
}
.RightArrow
{
    display: flex;
    align-items: center;
    margin-top: -12px;
}

.input2
{
    background-color: transparent;
    border: 0px none;
    outline: 0;
    border-width: 0 0 0px;
    border-color: #DADADA;
}
.add{
    margin-top: 15px;
    display: flex;
    justify-content: center;
}
.sub
{
    display: flex;
    align-items: center;
    gap: 15px;
    width: 77%;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    cursor: pointer;
    color: #464749;
}
.quoo
{
    display: flex;
    justify-content: center;
}
.quoo button
{
    color: white;
    width: 77%;
    height: 30px;
    border: 1px solid #3845B8;
    background-color: #3845B8;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    margin-top: 20px;
}
@media only screen and (max-width: 1244px) {

    .header
    {
        margin-top: 70px;
    }
    .right
    {
        margin-top: 70px;
    }
    

}

@media only screen and (max-width: 898px) {
    .getQuote
    {
        width: 110px;
        height: 40px;
    }
    .left
    {
        display: none;
    }
    .right
    {
        display: none;
    }
    .main{
        display: flex;
        justify-content: center;
        height: 153vh;
        background-color: #ECEAE7;
    }
    .new
    {
        display: block;
        width: 100%;
        margin-top: 100px;
    }
    .input
    {
        height: 48.2vh;
        top: 650px;
        left: 24%;        
        min-width: 343px;
        position: absolute;
        background-color: white;
    }
    .abc
    {
        margin-top: -20px;
        margin-left: 40px;
    }
   
    .header
    {
       margin-left: 30px;
   }
   .content p
   {
    width: 300px;
    margin-left: 30px;
   }
  }
@media only screen and (max-width: 790px) {
.input{
    left: 20%;
}
}
  
@media only screen and (max-width: 664px) {
    .main{
        height: 115vh;
    }
    .abc
    {
        margin-top: -110px;
        margin-left: 30px;
    }
    .getQuote
    {
        width: 110px;
    }
    .header h1
    {
        font-size: 20px;
        margin-left: 30px;
    }
    .input{
        left: 20%;
        width: 323px;
        height: 308px;
    }
 
}
@media only screen and (max-width: 544px) {

    .abc
    {
        margin-top: -130px;
        margin-left: 15px;
    }
    .trip{
        width: 76.24px;
        height: 22.02px;
    }
    .input
    {
        left: 17%;
        top: 620px;
    }
    .main{
        height: 112vh;
    }
}


@media only screen and (max-width: 474px) {
    .input
    {
        left: 14%;
        top: 590px;
    }
    .main{
        height: 106vh;
    }
}
@media only screen and (max-width: 440px) {

    .header
    {
        width: 230px;
        height: 90px;
    }
    .abc
    {
        margin-left: 15px;
    }
    .content p
    {
     width: 260px;
     font-size: 10px;
    }
    .getQuote
    {
        width: 93px;
        height: 31px;
        font-size: 11.6px;
    }
    .trip{
        width: 76.24px;
        height: 22.02px;
    }
    .input
    {
        left: 10%;
        top: 560px;
        min-width: 200px;
    }
   
}
@media only screen and (max-width: 419px) {
    .input
    {
        left: 70px;
        width: 250px;
    }
}
@media only screen and (max-width: 370px) {
    .input
    {
        left: 50px;
        width: 250px;
        top: 520px;
    }
}
@media only screen and (max-width: 336px) {
    .input
    {
        left: 38px;
        top: 480px;

    }
    .main{
        height: 90vh;
        background-color: #636466;
    }
}

@media only screen and (max-width: 1244px) {
    .header
    {
        margin-top: 70px;
        margin-left: -40px;
    }
    .right
    {
        margin-top: 70px;
    }
    

}

@media only screen and (max-width: 898px) {
    .getQuote
    {
        width: 110px;
        height: 40px;
    }
    .left
    {
        display: none;
    }
    .right
    {
        display: none;
    }
    .main{
        display: flex;
        justify-content: center;
        height: 130vh;
        background-color: #ECEAE7;
    }
    .new
    {
        display: block;
        width: 100%;
        margin-top: 0px;
    }
    .input
    {
        height: 48.2vh;
        top: 650px;
        
        min-width: 343px;
        position: absolute;
        background-color: white;
    }
    .abc
    {
        margin-top: -20px;
        margin-left: 40px;
    }
   
    .header
    {
       margin-left: -25px;
   }
   .content p
   {
    width: 300px;
    margin-left: 5px;
   }
  }
@media only screen and (max-width: 790px) {
    .header
    {
       margin-left: 10px;
   }
}

@media only screen and (max-width: 640px) {
    .header
    {
       margin-left: -25px;
   }
}
  
@media only screen and (max-width: 664px) {
    .main{
        height: 122vh;
    }
    .abc
    {
        margin-top: -110px;
        margin-left: 30px;
    }
    .getQuote
    {
        width: 110px;
    }
    .header h1
    {
        font-size: 20px;
    }
    .input{
        left: 20%;
        width: 343px;
        height: 368px;
    }
    .leftArrow {
        margin-right: 5px;
    }
    .RightArrow
    {
        margin-left: 5px;
    }
    .new
    {
        margin-top: 100px;
    }
}
@media only screen and (max-width: 544px) {
    .abc
    {
        margin-top: -130px;
        margin-left: 15px;
    }
    .trip{
        width: 76.24px;
        height: 22.02px;
        margin-top: 12px;
    }
    .input
    {
        left: 17%;
        top: 620px;
    }
    .main{
        height: 117vh;
    }
    .sub span
    {
        font-size: 14px;
    }
}


@media only screen and (max-width: 474px) {
    .input
    {
        left: 14%;
        top: 590px;
    }
    .main{
        height: 112vh;
    }
    .headd
    {margin: 10px;}
    .sub span
    {
        font-size: 14px;
    }
}
@media only screen and (max-width: 440px) {

    .header
    {
        width: 230px;
        height: 90px;
        margin-bottom: 30px;
    }
    .abc
    {
        margin-left: 15px;
    }
    .content p
    {
     width: 260px;
     font-size: 10px;
     margin-left: 10px;
    }
    .getQuote
    {
        width: 93px;
        height: 31px;
        font-size: 11.6px;
    }
    .trip{
        width: 76.24px;
        height: 22.02px;
    }
    .input
    {
        left: 10%;
        top: 560px;
        min-width: 200px;
    }
}
@media only screen and (max-width: 419px) {
    .input
    {
        left: 0px;
        top: 540px;
        width: 100%;
    }
    .main
    {
        height: 107vh;
    }
.datetime input
{
    height: 44px;
    width:123px;
    border: 1px solid #DADADA;

}
.datetime2 input
{
    width: 124px;

}
}
@media only screen and (max-width: 370px) {
    .input
    {
        left: 0px;
        width: 100%;
        top: 490px;
    }
    .datetime input
    {
        height: 44px;
        width:108px;
        border: 1px solid #DADADA;
    
    }
    .datetime2 input
    {
        width: 104px;
    }
    .main{
        height: 100vh;
    }
}
@media only screen and (max-width: 336px) {
    .input
    {
        left: 40px;
        top: 485px;
    }
}
