.carMain
{
margin:0px 1.8892508143322475vw;
}
.Header
{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.h1Container
{
    margin-top: 30px;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}
.h1Container h1
{
    font-size: 42px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}
.h1Container span
{
    color: #3845B8;
}
.pContainer
{
    padding-bottom: 3px;
    margin-bottom: 45px;
    padding-left:3px ;
    padding-right: 3px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.pContainer p{
    width: 50%;
    color: #656565;    
    font-size: 20px;
    text-align: justify;

    text-justify: inter-word;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
}

.carCards
{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap:25.84px ;
    column-gap: 10px;
}
.singleItem
{
    height: 570.16px;
    border-radius: 6.26;
    width: 32%;
    min-height: 550px;
    min-width: 387PX;
    background-color: white;
    box-shadow: 1px 0px 20px 7px rgba(230,230,230,0.75);
    -webkit-box-shadow: 1px 0px 20px 7px rgba(230,230,230,0.75);
    -moz-box-shadow: 1px 0px 20px 7px rgba(230,230,230,0.75);}
.imageHolder img
{
    height: 300px;
    width: 100%;
    margin-bottom: 8px;
}
.bookNow
{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.bookNow h1
{
    font-size: 21px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-left: 39.92px;
}
.button
{
    height: 44.69px;
    width: 134.06px;
    background-color: #3845B8;
    color: white;
    border: 1px solid #3845B8;
    border-radius: 26.81px;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    margin-top: 12px;
    margin-right: 23.92px;
    margin-bottom: 14.63px;
}
.singleItem p
{
    margin-bottom: 25.92px;
    font-size: 14px;
    font-weight: 300;
    font-family: 'Poppins', sans-serif;
    margin-right: 24.13px;
    margin-left: 39.32px;
}
.Footer
{
    width: 100%;
    padding-left: 57px;
    padding-right: 59px;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 35.41px;
    display: flex;
}
.subMain 
{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10.02px;
}
.subMain span
{
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.subMain h3
{
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}
@media only screen and (max-width: 1228px) {
    .carCards
{
    justify-content: center ;
    column-gap: 9.765625vw;
}

}
@media only screen and (max-width: 974px) {
    .carCards
{
    justify-content: center ;
    column-gap: 40px;
}

}
@media only screen and (max-width: 510px) {
    .carCards
{
    justify-content: center ;
    column-gap: 0px;
}
}

@media only screen and (max-width: 1199px) {
    .pContainer p 
    {
        font-size: 14px;
    }
}
@media only screen and (max-width: 991px) {
    .h1Container h1
    {
        font-size: 36px;
        margin-top: 50px;
    }
    .pContainer p 
    {
        font-size: 14px;
    }
    .imageHolder img
    {
        height: 255px;
    }
    .singleItem
{
    min-height: 500px;

}}
@media only screen and (max-width: 768px) {
    .h1Container h1
{
  font-size: 22px;
  margin-top: 50px;
}
}

@media only screen and (max-width: 411px) {
    .bookNow h1
    {
        font-size: 20px;
    }
    .button
    {
        height: 38px;
        width: 114px;
    }
    .singleItem
    {
        width: 315px;
        min-width: 180px;

    }
    .singleItem p
    {
        font-size: 14px;
    }
    .Footer
{
    padding: 0 35px 0 35px;

}
.subMain span
{
    font-size: 10px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.subMain h3
{
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}
}